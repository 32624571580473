<template>
  <div v-if="isLoading" class="flex justify-center">
    <LoadingIcon icon="three-dots" class="w-8 h-8" />
  </div>

  <LearnerView v-else>
    <VHeader>
      <template #avatar>
        <VImage
          :name="`${data?.firstname} ${data?.lastname}`"
          :src="getAvatarURL(data?.avatar?.view_path)"
          :width="12"
          :height="12"
          class="items-center justify-center"
        />
      </template>

      <template #title>{{ data?.firstname }} {{ data?.lastname }}</template>

      <template #subtitle>@{{ data?.username }}</template>

      <template #content>
        <div
          class="mt-3 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="flex items-center">
            <div class="label uppercase text-right mr-2 w-24">
              {{ $t("app.competency_score") }}
            </div>

            <VScore :score="data?.competency_score" />

            <VHint class="self-start ml-2">
              {{ $t("hints.teaching_learners_competency_score") }}
            </VHint>
          </div>
        </div>

        <div class="mt-3 lg:mt-0 flex-1 dark:text-gray-300 px-5 pt-5 lg:pt-0">
          <div class="flex items-center">
            <div class="label uppercase text-right mr-2 w-24">
              {{ $t("app.completion_score") }}
            </div>

            <VScore :score="data?.completion_score" />

            <VHint class="self-start ml-2">
              {{ $t("hints.teaching_learners_completion_score") }}
            </VHint>
          </div>
        </div>
      </template>

      <template #tabs>
        <VTabs
          :current-tab="currentTab"
          :tabs="tabs"
          @click="onClickRedirect"
        />
      </template>
    </VHeader>

    <router-view />
  </LearnerView>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
// Composables
import useTabs from "@/composables/useTabs";
import useRequest from "@/composables/useRequest";
import useDisplay from "@/composables/useDisplay";
// Components
import LearnerView from "./LearnerView";
import VHeader from "@/components/VHeader";
import VTabs from "@/components/VTabs";
import VScore from "@/components/VScore";
import VImage from "@/components/VImage";
import VHint from "@/components/VHint";

export default {
  components: {
    LearnerView,
    VHeader,
    VTabs,
    VScore,
    VImage,
    VHint
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const route = useRoute();

    // Constants
    const baseRoute = "teaching-learners";
    const allTabs = [
      "overview",
      "competencies",
      "feedback",
      "lessons",
      "evaluations",
      "attestations",
      "trainings",
      "collections"
    ];

    // Composables
    const { buildTab } = useTabs();
    const { request } = useRequest();
    const { getAvatarURL } = useDisplay();

    // Data
    const data = ref({});
    const isLoading = ref(true);

    // Computed
    const currentTab = computed(() => {
      const availableTabs = allTabs.map(tab => ({
        route: `${baseRoute}-${tab}`,
        name: tab
      }));
      const currentTab = availableTabs.find(tab => route.name === tab.route);

      return currentTab?.name ?? "";
    });

    const tabs = computed(() => {
      return allTabs.map(tab => {
        return buildTab({ name: tab });
      });
    });

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: "teaching.learners.read",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
    };

    const onClickRedirect = tab => {
      router.push({
        name: `${baseRoute}-${tab}`,
        params: {
          id: props.id
        }
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      isLoading.value = true;
      await getData();
      isLoading.value = false;
    });

    return {
      onClickRedirect,
      tabs,
      currentTab,
      data,
      isLoading,
      // useDisplay
      getAvatarURL
    };
  }
};
</script>
